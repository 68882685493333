import React from 'react';
import FooterCopyrightPage from "./FooterCopyrightPage"

const FooterBlockPage = () => {
    return(
        <>
            <FooterCopyrightPage />        
        </>
    )
}

export default FooterBlockPage