import * as React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import Seo from "../components/seo";
import TopDarkRelative from "../components/Modules/Top/TopDarkRelative";
import Footer from "../components/Modules/FooterPage";
import DairytechPage from "../components/OtherPages/Promos/Dairytech";

const PromoPage = () => (
  <>
    <Seo 
      title="Выставка Агропродмаш 22" 
      description="Схема размещения стенда Рефинжиниринг на выставке Агропродмаш 22"
    />

    <Container fluid>
        <Row>
            <Col>
              <TopDarkRelative />
            </Col>
        </Row>
            <DairytechPage />
        <Row>
            <Col style={{padding: '0'}}>
                <Footer />
            </Col>
        </Row>
    </Container>

  </>
)

export default PromoPage