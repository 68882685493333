import React from "react";
import TopDarkModeRelative from './TopDarkModeRelative';


const TopDarkRelative = () => (
<>
        <TopDarkModeRelative />
</>
)

export default TopDarkRelative