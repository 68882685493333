import React from 'react';
import FooterBlockPage from "./FooterBlockPage"

const FooterPage = () => {
    return(
        <>
            <FooterBlockPage />        
        </>
    )
}

export default FooterPage