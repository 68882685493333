import React from "react"

import {Button} from "react-bootstrap"

const RefButton = ({children}) => (

  <>
    <Button variant="ref" size="xl" type="submit" className="ref-blue-button">
      {children}
    </Button>
  </>
)

export default RefButton