import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RefButton from "../../Elements/Buttons/RefButtons/RefStyledButtons/RefButton"

const AgroprodmashPage = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h3>С 25 по 27 января 2023г. мы участвуем в 21-й международной выставке оборудования для производства молока и молочной продукции "Dairytech 2023"</h3>
                    </Col>
                </Row>
                <hr></hr>
                <Row className="agroprodmash-block">
                    <Col xl={4}>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '100%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/logodtmain.png" 
                            alt="Логотип Dairytech 2023" />
                        </figure>
                    </Col>
                    <Col xl={8} style={{display: "grid", alignContent: "center", justifyContent: "center"}}>
                        <p className="promodate" >МВЦ «Крокус-экспо»</p>
                        <p className="promoplace">Павильон 1, зал 4</p>
                        <p className="promoplace">Стенд А2089</p>
                        <RefButton>
                            <a href="https://dairytech-expo.ru/Rus/get-eticket" 
                            style={{color: "#fff"}}
                            rel="noopener noreferrer" 
                            target = "_blank"  
                            aria-label="Главная страница"
                            >
                                Получить билет
                            </a>
                        </RefButton>
                    </Col>
                </Row>

                <hr></hr>
                <Row>
                    <Col>
                        <h4>Как добраться:</h4>
                        <span>МВЦ «Крокус-экспо»</span>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '70%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/pavilion.jpg" 
                            alt="Крокус-экспо схема проезда" />
                        </figure>
                    </Col>
                </Row>

                <hr></hr>
                <Row>
                    <Col>
                        <h4>Как нас найти:</h4>
                        <span>Общая схема выставки:</span>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '80%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/stenddairy.jpg" 
                            alt="Логотип Агропродмаш" />
                        </figure>
                        <span>Стенд "Рефинжиниринг" А2089:</span>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <p>
                        Компания "Рефинжиниринг" занимается промышленным холодоснабжением с 2013г. 
                        Сегодня мы внедряем передовые технические инновации в части энергосберегающих и экологичных технологий. 
                        Высочайшие стандарты качества нашей работы позволяют поддерживать репутацию надёжного и ответственного партнёра.
                        </p>
                    </Col>
                </Row>
                <Row>
                <Col xl={7}>
                        <h4>На выставке "Dairytech 2023" мы представляем один экспонат:</h4>
                        <p>
                        Единый климатический блок для точного поддержания температурно-влажностного режима (микроклимата):
                        </p>
                        <ul className="post-ul-dot">
                            <li className="post-li-dot">Охлаждение;</li>
                            <li className="post-li-dot">Нагрев;</li>
                            <li className="post-li-dot">Осушка;</li>
                            <li className="post-li-dot">Увлажнение;</li>
                            <li className="post-li-dot">Интенсивность обдува продукта.</li>
                        </ul>
                        <a href="/equipment/klimablock">О климатическом блоке</a>
                    </Col>
                    <Col>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '40%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/gjelblock.jpg" 
                            alt="Логотип Агропродмаш" />
                        </figure>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Напольное исполнение блока для максимально эффективной
                            циркуляции воздуха внутри камеры:
                            Всасывание воздуха осуществляется с уровня пола.
                            Подготовленный в климатическом блоке воздух подается
                            в текстильные воздуховоды. Точный подбор перфорации
                            и сопел в воздуховодах обеспечивает равномерное
                            распределение воздуха по всему объему камеры и
                            продукту вне зависимости от конфигурации и расстановки
                            стеллажного оборудования.
                        </p>
                    </Col>
                </Row>
             
                <Row>
                    <Col>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '100%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/gjel-002.jpg" 
                            alt="Логотип Агропродмаш" />
                        </figure>
                    </Col>
                    <Col>
                        <figure style={{display: 'block'}}>
                            <img style={{width: '100%', height: 'auto'}} 
                            src="https://raw.githubusercontent.com/surreum/imagestore/main/refeng/promo/gjel-003.jpg" 
                            alt="Логотип Агропродмаш" />
                        </figure>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Узнать более подробную информацию о сырном производстве и климатическом блоке производства Рефинжиниринг, 
                            а также посмотреть больше фотографий вы можете на странице выполненного нами 
                            <a href="/realizovannye_proekty/gjelskoe-podvorie"> проекта сырного производства в г.Гжель </a> 
                            для фермерского хозяйства "Гжельское Подворье".
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            <strong>Представители компании Рефинжиниринг ждут Вас с 25-го января в МВЦ «Крокус-экспо» на нашем стенде в Павильоне 1, зал 4.</strong>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default AgroprodmashPage;
